<template>
  <Loader :show="loading" v-if="loading" />
  <div class="pb-2" v-else>
    <CategoryTop :breadcrumbs-parent-routes="['trainings', 'bridgestoneSchulungen']"
      :breadcrumbs-route-name="training.title" class="mb-3" contentClass="fix-height" mainClass="training-container" />
    <MDBRow>
      <MDBCol xl="7" cols="12" class="image-col">
        <img v-if="training && training.poster" :src="getImage(training.poster)" :alt="training.title" />
      </MDBCol>
      <MDBCol xl="5" cols="12">
        <div class="textBox h-auto textBox__training">
          <div v-if="training" v-html="training.title" class="fw-bold"></div>
          <p class="fw-bold">
            {{ $t("trainings.singleLecture.index.costs") }}
          </p>
          <div v-if="trainingDetails">
            <div v-for="(trainingDetail, index) in trainingDetails" :key="index" class="pb-2">
              <div class="fw-bold pb-2">
                {{ setType(trainingDetail.b_training_type_id) }}
              </div>
              <div>
                {{
                  $t("trainings.singleLecture.index.duration", {
                    duration: trainingDetail.duration[currentLocale],
                  })
                }}
              </div>
              <div v-html="$t('trainings.singleLecture.index.cost', {
                cost: setCost(trainingDetail.b_training_cost_id),
                price: trainingDetail.price[currentLocale],
                points: trainingDetail.price[currentLocale] * 4,
              })
                "></div>
              <div v-html="$t('trainings.singleLecture.index.participantsNr', {
                min: trainingDetail.min_participant[currentLocale],
                max: trainingDetail.max_participant[currentLocale],
              })
                "></div>
              <div v-if="trainingDetail.extras">
                ({{ trainingDetail.extras[currentLocale] }})
              </div>
            </div>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import Loader from "@/components/General/Loader/index.vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import CategoryTop from "@/components/General/CategoryTop/index.vue";
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const loading = computed(() => store.getters.getPending);

const getImage = (img) => {
  const image = img ? img : "";

  return `${image}`;
};
const training = computed(() => store.getters.getBTraining);
const trainingDetails = computed(() => store.getters.getBTrainingDetails);
const trainingCosts = computed(() => store.getters.getBTrainingCosts);
const trainingTypes = computed(() => store.getters.getBTrainingTypes);
const currentLocale = computed(() => store.getters.getCurrentLocale);

const setCost = (costId) => {
  let trainingCost = trainingCosts.value.filter(
    (trainingCost) => trainingCost.id == costId
  );

  return trainingCost.length ? trainingCost[0].type : "";
};

const setType = (typeId) => {
  let trainingType = trainingTypes.value.filter(
    (trainingType) => trainingType.id == typeId
  );

  return trainingType.length ? trainingType[0].name[currentLocale.value] : "";
};
</script>

<style scoped lang="scss">
@import "../../../styles/_variables.scss";
@import "@/styles/training.scss";
</style>
