<template>
  <div id="client-nr">
    <MDBRow class="mb-4">
      <MDBCol col="12" lg="4">
        <h4 class="mb-2 mt-1 text-uppercase">
          <b>{{ $t("profile.yourCsNr") }}</b>
        </h4>
        <div v-html="$t('profile.clientNrContent')"></div>
      </MDBCol>
      <MDBCol col="12" lg="8">
        <MDBRow class="mb-3">
          <MDBCol col="12" md="5" class="fw-bold text-uppercase">
            {{ $t("profile.clientNrSingular") }}
          </MDBCol>
          <MDBCol col="12" md="5" class="fw-bold text-uppercase">
            {{ $t("profile.supplySource") }}
          </MDBCol>
        </MDBRow>
        <MDBRow v-for="(data, index) in me.wholesalers_dealers" :key="index">
          <MDBCol col="12" md="5">
            <div :class="data?.parent_id !== null ? 'ps-2' : ''">
              <input v-model="data.customer_number" type="text" class="form-control" :placeholder="`${$t('her')} ${$t(
                'profile.clientNrSingular'
              )}`" :aria-label="`${$t('her')} ${$t(
                'profile.clientNrSingular'
              )}`" aria-describedby="basic-addon1" :disabled="data.verifiedCustomerNumber" />
            </div>
          </MDBCol>
          <MDBCol col="12" md="4" v-if="!data.expected_name">
            <select class="form-select mb-1" aria-label=".form-select-lg example" v-model="data.wholesaler_id"
              :disabled="data.verifiedCustomerNumber">
              <option v-for="(wholesaler, index) in allWholesalers" :key="index" :value="wholesaler.id"
                v-show="data.verifiedCustomerNumber">
                {{ wholesaler.name }}
              </option>
              <option v-for="(wholesaler, index) in wholesalers" :key="index" :value="wholesaler.id"
                v-show="!data.verifiedCustomerNumber">
                {{ wholesaler.name }}
              </option>
            </select>
          </MDBCol>
          <MDBCol col="12" md="4" v-else>
            <div v-if="!data.verifiedCustomerNumber">
              <input v-model="data.expected_name" type="text" class="form-control" :placeholder="`${$t(
                'profile.selectWholeSaler'
              )}`" :aria-label="`${$t(
                'profile.selectWholeSaler'
              )}`" aria-describedby="basic-addon1" />
            </div>
            <div v-else>
              <select class="form-select mb-1" aria-label=".form-select-lg example" v-model="data.wholesaler_id"
                :disabled="data.verifiedCustomerNumber">
                <option v-for="(wholesaler, index) in allWholesalers" :key="index" :value="wholesaler.id"
                  v-show="data.verifiedCustomerNumber">
                  {{ wholesaler.name }}
                </option>
                <option v-for="(wholesaler, index) in wholesalers" :key="index" :value="wholesaler.id"
                  v-show="!data.verifiedCustomerNumber">
                  {{ wholesaler.name }}
                </option>
              </select>
            </div>
          </MDBCol>
          <MDBCol col="12" md="3">
            <MDBBtn @click="saveCustomerNumber(data)" type="button" color="danger"
              class="d-flex justify-content-center align-items-center" v-if="!data.verifiedCustomerNumber"><span>{{
                $t("buttons.sendButton") }}</span>
            </MDBBtn>
          </MDBCol>
        </MDBRow>
        <NewCustomerNumber v-if="!isNetherland" />
      </MDBCol>
    </MDBRow>
    <MDBModal v-model="modal" tabindex="-1" centered size="xl" @hide="hideModal">
      <ConfirmCsNumber :data="csNumberData" @close="(e) => hideModal()" />
    </MDBModal>
  </div>
</template>

<script setup>
import { MDBRow, MDBCol, MDBBtn, MDBModal } from "mdb-vue-ui-kit";

import { useStore } from "vuex";
import { computed, defineExpose, reactive, watch, ref } from "vue";
import NewCustomerNumber from "@/components/User/ProfileForms/NewCustomerNumber.vue";
import ConfirmCsNumber from "@/components/User/ProfileForms/ConfirmCsNumber.vue";

const LANG = localStorage.getItem("lang");
const isNetherland = computed(() => LANG === "nl");

const store = useStore();
const me = computed(() => store.getters.getMe);

const allWholesalers = computed(() => store.getters.getAllWholesalers);
const wholesalers = computed(() => store.getters.getWholesalers);

const models = reactive({
  customerNumbers: [
    {
      customer_number: "",
      wholesaler_id: null,
    },
  ],
});
const modal = ref(false);
const csNumberData = ref(null);

const saveCustomerNumber = async (payload) => {
  csNumberData.value = {
    wholesaler_id: payload.wholesaler_id,
    customer_number: payload.customer_number,
    dealer_id: me.value.plainId,
    id: payload?.id ?? null,
    expected_name: payload.expected_name,
  };
  modal.value = true;
};

const hideModal = () => {
  modal.value = false;
}

watch(me, async (newVal) => {
  if (newVal) {
    models.customerNumbers = [
      {
        customer_number: "",
        wholesaler_id: null,
      },
    ];
  }
});


defineExpose({
  models,
});
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

#client-nr {
  padding: 9px 18px 9px 9px;
  background: $secondaryGray;

  h5 {
    margin-bottom: 30px;
  }
}
</style>
