<template>
  <Loader :show="loading" v-if="loading" />
  <div v-else>
    <RegisterForm :trainingTypes="trainingTypes" @refresh="refresh()" v-if="training" />
  </div>
</template>

<script setup>
import Loader from "@/components/General/Loader/index.vue";
import RegisterForm from "@/components/Trainings/SingleSchulungen/Form/Register.vue";
import { computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const route = useRoute();
const user = computed(() => store.getters.getMe);
const loading = computed(() => store.getters.getPending);

const fetchTraining = () => {
  store.dispatch("loadBTrainingById", route.params.id);
};

const fetchTrainingResult = async () => {
  const data = { trainingId: route.params.id, userId: user.value.id };
  await store.dispatch("loadBTrainingResult", data);
};

const refresh = () => {
  fetchTraining();
  store.dispatch("me");
  fetchTrainingResult();
};

onMounted(() => {
  refresh();
});

const training = computed(() => store.getters.getBTraining);
const trainingTypes = computed(() => store.getters.getBTrainingTypes);
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
</style>
