<template>
  <WelcomeBoxContent v-if="canSeeWelcomeBox" />
  <MailBoxContent v-if="canSeeMessageBox" />
  <TyreBox v-if="canSeeTyreBox" />
</template>

<script setup>
import TyreBox from "@/components/Dashboard/TyreBox.vue";
import WelcomeBoxContent from "@/components/Dashboard/WelcomeBoxContent.vue";
import MailBoxContent from "@/components/Dashboard/MailBoxContent.vue";
import TokenService from "@/services/storage.service";
import { useStore } from "vuex";
import { computed, onMounted } from "vue";

const store = useStore();

const fetchLeftColumn = computed(() => store.getters.getFetchLeftColumn);
const permissions = computed(() => store.getters.getPermissions);
const meIsPending = computed(() => store.getters.getMeIsPending);
const canSeeMessageBox = computed(() => {
  return permissions.value && TokenService.showElement("messageBox.see")
})

const canSeeTyreBox = computed(() => {
  return permissions.value && TokenService.showElement("tyreBox.see")
})

const canSeeWelcomeBox = computed(() => {
  return permissions.value && TokenService.showElement("welcomeBox.see")
})

onMounted(async () => {
  if (!fetchLeftColumn.value) {
    if (!meIsPending.value) {
      await store.dispatch("me");
    }
    store.dispatch("checkLeftColumnFetched");
  }
})
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";
@import "@/styles/welcome_box.scss";
@import "@/styles/mailbox.scss";

:deep(.card-title) {
  font-size: 20px;

  @media screen and (min-width: $xxlBreakpoint) {
    font-size: 25px;
  }
}
</style>
