<template>
  <MDBCard class="container-height mt-3 mt-lg-4" :class="[
    `${user?.commitment_letter_accept && user?.intro == 1 ? 'intro__step' : ''
    }`,
  ]">
    <MDBCardBody>
      <MDBCardText class="font-10 font-xl-18">
        <div v-if="pointBonusByYear">
          <div v-for="(tyres, index) in pointBonusByYear" :key="index">
            <b>{{ $t("wholesaler.dashboard.overview.all") }} {{ $t("contact.form.tires").toLocaleLowerCase() }} {{
              tyres.year }}:</b> {{
                tyres.yearly_sum }}
          </div>
        </div>
        <div v-if="tyresAmount">
          <div>
            <b>13-16":</b>
            {{ tyresAmount["13_16"]
            }} |
            <b>17-18":</b>
            {{ tyresAmount["17_18"]
            }} |
            <b>19"+:</b>
            {{ tyresAmount["19_"] }}
          </div>
        </div>
        <div v-else>
          <div>
            <b>13-16":</b> 0 |
            <b>17-18":</b> 0 |
            <b>19"+:</b> 0
          </div>
        </div>
        <div class="pt-0 p-relative">
          <b>{{ $t("tyreBox.target") }} {{ currentYear }}:&nbsp;</b><span
            v-if="bonusMultiplicator.length > 0 && checkUserGoal()">{{
              $t(`multiplicator.goals.${checkUserGoal().name}`) }}
          </span>
          <span v-else>{{ $t('multiplicator.chooseTarget') }}</span>
          <span class="info-notification cursor--pointer" @click="openModal()">!</span>
        </div>
        <div class="pt-0" v-if="bonusMultiplicator.length > 0 && checkUserGoal()">
          <span v-html="$t('multiplicator.minTyres', {
            tyre: checkUserGoal().min_tyres
          })"></span>
        </div>
        <div class="pt-0" v-if="bonusMultiplicator.length > 0 && checkUserGoal()">
          <b>{{
            $t(`multiplicator.goals.${checkUserGoal().name}`) }} bonus = X {{ checkUserGoal().factor }}</b>
        </div>
        <div class="pt-0">
          <b>{{ $t("tyreBox.entry") }} {{ currentYear }}:</b> {{ shopLimit?.limit }}
          <span class="text-lowercase">{{ $t("tyreBox.title") }}</span>
        </div>
      </MDBCardText>
    </MDBCardBody>
    <MDBModal v-model="modal" tabindex="-1" centered size="xl">
      <Loader :show="loading" v-if="loading" />
      <MDBCard v-else>
        <MDBCardBody>
          <MultiplicatorInfo />
        </MDBCardBody>
      </MDBCard>
    </MDBModal>
  </MDBCard>
</template>

<script setup>
import Loader from "@/components/General/Loader/index.vue";
import MultiplicatorInfo from "@/components/KickbackFee/MultiplicatorInfo.vue";
import {
  MDBCard,
  MDBCardBody,
  MDBCardText, MDBModal
} from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import { ProfileService } from "@/services/profile.service";

const store = useStore();

const user = computed(() => store.getters.getMe);
const shopLimit = computed(() => store.getters.getTyreLimit);
const tyresAmount = computed(() => user?.value.specified_tyres_amount);
const bonusMultiplicator = computed(() => store.getters.getBonusMultiplicator);
const pointBonusByYear = computed(() => store.getters.getPointBonusByYear);
const loading = computed(() => store.getters.getPending);

const modal = ref(false)
const currentYear = new Date().getFullYear()

const openModal = () => {
  modal.value = true;
};
const initialData = async () => {
  const currentYear = new Date().getFullYear()
  await store.dispatch('fetchTyreLimit', currentYear)
  const bonusData = { user_id: user.value.plainId }
  await ProfileService.createBonus(bonusData);
}


const checkUserGoal = () => {
  if (bonusMultiplicator.value.length > 0) {
    const goal = bonusMultiplicator.value.find(bonus => bonus.id === user.value.bonus_multiplicator_id)
    return goal ? goal : null
  }
  return false
}
onMounted(async () => {
  await initialData()
})
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";
@import "@/styles/welcome_box.scss";
@import "@/styles/mailbox.scss";
@import "@/styles/points_overview.scss";

.container-height {
  height: 50%;
  max-height: 300px;
}
</style>
