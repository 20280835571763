<template>
  <swiper :slides-per-view="1" :space-between="0" :modules="[Navigation, Pagination, Autoplay]" loop :autoplay="{
    delay: props.slides.length > 1 ? 5000 : 500000000000,
    disableOnInteraction: false,
  }" :navigation="props.slides.length > 1" :initial-slide="1" :pagination="{
    clickable: true,
    type: 'custom',
    renderCustom: (swiper, current, total) =>
      `<div class='swiper-custom-pagination'>${current} ${$t(
        'of'
      )} ${total}</div>`,
  }">
    <swiper-slide v-for="(slide, key) in props.slides" :key="key"
      :class="`swiper-item ${slide.link_url[currentLocale] ? 'cursor--pointer' : ''}`"
      @click="redirect(slide.link_url[currentLocale], slide.new_tab, slide.survey_key)">
      <img :src="slide.poster[currentLocale]" class="swiper-img" />
      <div class="swiper-content" v-if="slide.show_text">
        <h3 :class="`swiper-title ${slide.color ? `text-${slide.color}` : ''}`">
          {{ slide.title[currentLocale] }}
        </h3>
        <p :class="`swiper-description ${slide.color ? `text-${slide.color}` : ''
          }`" v-html="slide.description[currentLocale]"></p>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script setup>
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import { defineProps, computed } from "vue";
import { useStore } from "vuex";
import "../General/TextSlider/slider.scss";

const props = defineProps({
  slides: Object,
});
const store = useStore();
const currentLocale = computed(() => store.getters.getCurrentLocale);

const redirect = (link, new_tab, survey_key) => {
  if (!link) return;

  localStorage.setItem("hcvisitor", survey_key);
  const tab = new_tab ? "_blank" : "_self";
  window.open(link, tab);
};
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.swiper,
.swiper-wrapper {
  height: 100%;
}

.swiper-item {
  height: 100%;
  aspect-ratio: 16 / 9;
}

.swiper-content {
  color: white;
  background: rgba(0, 0, 0, 0.4);
  padding: 32px 125px 32px 32px;
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;

  @media screen and (max-width: $lgBreakpoint) {
    padding: 35px 25px;
  }
}

.swiper-title {
  font-size: 35px;
  text-shadow: 1px 1px 10px rgb(0 0 0 / 50%);

  @media screen and (max-width: $lgBreakpoint) {
    font-size: 25px;
  }
}

.swiper-description {
  font-size: 18px;
  margin: 0;
  text-shadow: 1px 1px 10px rgb(0 0 0 / 50%);

  @media screen and (max-width: $lgBreakpoint) {
    font-size: 16px;

    br {
      display: none;
    }
  }
}

.swiper-img {
  width: 100%;
  height: inherit;
}
</style>
