<template>
  <MDBCard v-if="isDutch && canSeeAccountStatement" class="score-container mt-3" :class="[
    {
      intro__step: user?.commitment_letter_accept && user?.intro == 3,
    },
  ]">
    <MDBCardBody>
      <MDBCardTitle class="title title-slash text-uppercase font-12 font-md-15 font-xxl-25">
        {{ $t("dashboard.bankstatement.yourKickback") }}
      </MDBCardTitle>
      <MDBCardText>
        <div class="fw-bold" v-if="pointBonusByYear">
          <div v-for="(data, index) in pointBonusByYear" :key="index" :class="[{ 'font-18 font-md-25': currentYear == data.year }, { 'font-14': currentYear != data.year },
          { 'success-green': !user?.payouts?.includes(data.year) }, { 'text-dark-gray': user?.payouts?.includes(data.year) }
          ]">
            {{ data.year }}: {{
              user.display_kickbacks ? data.kick_back_fee : 0 }} Euro
          </div>
        </div>
        <p class="font-12">
          {{ $t("dashboard.bankstatement.lastUpdate") }}
          {{
            lastUpdate?.date
              ? dates.parseDate(lastUpdate?.date)
              : dates.parseDate(new Date())
          }}
        </p>
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>
</template>

<script setup>
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { ref, watch, computed, onMounted } from "vue";
import dates from "@/plugins/dates";
import TokenService from "@/services/storage.service";

const LANG = localStorage.getItem("lang");
const isDutch = computed(() => LANG === "nl");
const store = useStore();

const user = computed(() => store.getters.getMe);
const pointBonusByYear = computed(() => store.getters.getPointBonusByYear);
const isStaff = ref(false);
const canSeeAccountStatement = ref(false);
const currentYear = new Date().getFullYear()

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "staff")) {
    isStaff.value = true;
  }
  canSeeAccountStatement.value = TokenService.showElement("kickBackFeeBox.see");
});

const lastUpdate = computed(() => store.getters.getLastBankStatement);

onMounted(() => {
  canSeeAccountStatement.value = TokenService.showElement(
    "accountStatement.see"
  );
});
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.score-container {
  height: 100%;
  max-height: 204px;
}
</style>
