<template>
  <MDBRow v-if="training">
    <MDBCol cols="12" lg="7">
      <div class="textBox textBox--small">
        <div class="fw-bold pb-2">
          {{ $t("trainings.singleLecture.registerForm.title") }}
        </div>
        <div v-if="training" v-html="description"></div>
      </div>
    </MDBCol>
    <MDBCol cols="12" lg="5" v-if="!result.success && !isWholesaler && !isWholesalerStaff">
      <VeeForm class="form user-form" @submit="onSubmit">
        <p class="fw-bold">
          {{ $t("trainings.singleLecture.registerForm.inquiry") }}
        </p>
        <p>
          {{ $t("trainings.singleLecture.registerForm.interested") }}
          <span class="fw-bold">{{ training.title }}</span>
        </p>
        <div v-for="trainingType in trainingTypes" :key="trainingType.id"
          :class="`${trainingDetails.length < 2 ? 'd-none' : ''}`">
          <input class="form-check-input" type="radio" :name="trainingType.id" v-model="selectedTrainingType"
            :value="trainingType.id" :disabled="trainingDetails.length < 2" />
          <label class="form-check-label" :for="trainingType.id">{{
            trainingType.name[currentLocale]
          }}</label>
        </div>
        <div>
          <p>
            {{ $t("trainings.singleLecture.registerForm.participantsNr") }}
          </p>
          <div>
            <Field type="number" class="form-control" :placeholder="$t('trainings.singleLecture.registerForm.participantsNr')
              " :aria-label="$t('trainings.singleLecture.registerForm.participantsNr')
                " aria-describedby="basic-addon1" v-model="participantCount" :rules="{ min_value: 1 }"
              name="participantCount" label="Teilnehmerzahl" />
            <ErrorMessage name="participantCount" class="text-danger text-small text-end m-0 pt-1" />
          </div>
        </div>
        <p class="fw-bold" v-if="canUsePoints">
          {{ $t("trainings.singleLecture.registerForm.pointsFromBalance") }}
        </p>
        <div v-if="canUsePoints">
          <Field type="number" class="form-control" :placeholder="$t('points')" :aria-label="$t('points')"
            aria-describedby="basic-addon1" v-model="setPoints" name="setPoints"
            :rules="{ min_value: 0, max_value: maxPoints, required: only_points_payment }" :label="$t('points')"
            :disabled="!canUsePoints || isStaff" />
          <ErrorMessage name="setPoints" class="text-danger text-small text-end m-0 pt-1" />
        </div>
        <p v-if="calculatePriceDiscount > 0 && canUsePoints">
          {{
            $t("trainings.singleLecture.registerForm.reduction", {
              points: calculatePriceDiscount,
            })
          }}
        </p>
        <div class="pt-3">
          <button type="submit" class="btn btn-danger text-uppercase" :disabled="isStaff">
            {{ $t("trainings.singleLecture.registerForm.request") }}
          </button>
        </div>
      </VeeForm>
    </MDBCol>
    <Success v-else-if="result.success && !isWholesaler && !isWholesalerStaff" />
  </MDBRow>
</template>

<script setup>
import { Form as VeeForm, Field, ErrorMessage } from "vee-validate";
import Success from "./Success.vue";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { defineProps, ref, computed, defineEmits, watch } from "vue";
import i18n from "@/plugins/i18n";
import { useStore } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
const store = useStore();

defineProps({
  trainingTypes: Array,
});

const emit = defineEmits(["refresh"]);

const isWholesaler = ref(false);
const isWholesalerStaff = ref(false);
const isStaff = ref(false);
const selectedTrainingType = ref(1);
const setPoints = ref(0);
const participantCount = ref(0);
const user = computed(() => store.getters.getMe);
const profile = computed(() => store.getters.getProfile);
const trainingDetails = computed(() => store.getters.getBTrainingDetails);
const maxPoints = computed(() => user.value.points);
const result = computed(() => store.getters.getBTrainingResult);
const training = computed(() => store.getters.getBTraining);
const currentLocale = computed(() => store.getters.getCurrentLocale);
const canUsePoints = ref(false);
const description = ref(null);
const only_points_payment = ref(false)

const calculatePriceDiscount = computed(() => {
  return Math.round(setPoints.value * 0.25);
});

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "wholesalerStaff")) {
    isWholesalerStaff.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "staff")) {
    isStaff.value = true;
  }
});

const onSubmit = async () => {
  const trainingDetail = trainingDetails.value.find(
    (el) => el.b_training_type_id == selectedTrainingType.value
  );

  console.log(trainingDetail)
  const priceValidation = trainingDetail.b_training_cost_id == 1 ? participantCount.value * trainingDetail.price * 4 : trainingDetail.price * 4;

  console.log(priceValidation)
  if (only_points_payment.value && maxPoints.value < priceValidation) {
    toast(i18n.global.t("toastMessages.canAddProduct"), {
      type: "error",
      theme: "colored",
      transition: toast.TRANSITIONS.SLIDE,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } else {
    const objData = Object.assign({}, trainingDetail);

    let data = {
      user_id: user.value.id,
      b_training_id: training.value.id,
      b_training_type_id: selectedTrainingType.value,
      b_training_cost_id: objData.b_training_cost_id,
      participant_number: participantCount.value,
      used_points: setPoints.value,
      price: calcPrice(objData),
    };
    const response = await store.dispatch("storeBResult", data);

    if (response) {
      emit("refresh");
    }

  }
};

const calcPrice = (objData) => {
  const currentPrice =
    selectedTrainingType.value == 1
      ? objData.price[currentLocale.value] * participantCount.value
      : objData.price[currentLocale.value];

  return currentPrice - calculatePriceDiscount.value;
};

watch(trainingDetails, () => {
  const objData = Object.assign({}, trainingDetails.value);

  if (trainingDetails.value.length < 2) {
    selectedTrainingType.value = objData[0].b_training_type_id;
  }
});

watch(training, () => {
  canUsePoints.value = training.value && training.value.can_spend_points && training.value.can_spend_points.includes(
    profile.value.group_id
  );

  only_points_payment.value = training.value && training.value.only_points_payment

  description.value = setDescription();
});

const setDescription = () => {
  if (
    training.value && training.value.can_spend_points &&
    training.value.can_spend_points.includes(profile.value.group_id)
  ) {
    return training.value.description;
  } else {
    return training.value.default_description;
  }
};
</script>
