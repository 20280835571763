<template>
  <MDBCard class="pb-3 pb-lg-4 welcome--main welcome__height" :class="[
    `${user?.commitment_letter_accept && user?.intro == 1 ? 'intro__step' : ''
    }`,
  ]">
    <MDBCardBody>
      <MDBCardTitle class="title text-uppercase title-slash">
        {{ $t("pobox.title") }}
      </MDBCardTitle>
      <MDBCardText>
        <p v-if="messages > 0">
          {{ $t("dashboard.pobox.newMessages") }}
          <span class="message-notification">{{ messages }}</span>
        </p>
        <DashboardLink v-if="!isWholesaler && !isStaff" class="text-uppercase" :to="{ name: 'mailbox' }"
          :disabled="user?.commitment_letter_accept && user?.intro == 1">
          {{ $t("dashboard.pobox.link") }}
        </DashboardLink>
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>

</template>

<script setup>
import { MDBCardBody, MDBCardTitle, MDBCardText, MDBCard } from "mdb-vue-ui-kit";
import DashboardLink from "../Dashboard/Dashboard-link.vue";
import { useStore } from "vuex";
import { computed, watch, ref } from "vue";

const store = useStore();

const user = computed(() => store.getters.getMe);
const messages = computed(() => {
  return store.getters.getUnreadMessages;
});
const isWholesaler = ref(false);
const isStaff = ref(false);

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
  if (newVal && newVal.roles.find((item) => item.name == "staff")) {
    isStaff.value = true;
  }
});
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";
@import "@/styles/welcome_box.scss";
@import "@/styles/mailbox.scss";

:deep(.card-title) {
  font-size: 20px;

  @media screen and (min-width: $xxlBreakpoint) {
    font-size: 25px;
  }
}

.message-notification {
  background: #f2790d;
  color: white;
  display: inline-block;
  border-radius: 25px;
  width: 18px;
  height: 18px;
  font-size: 12px;
  text-align: center;
  margin-left: 5px;
}

.title {
  position: relative;
}
</style>
