<template>
  <MDBRow>
    <MDBCol xl="5" cols="12" class="mb-4 image-col">
      <div class="lightboxContainer">
        <div class="lightbox" @click="showLightBox = true"></div>
        <iframe v-if="trainingData.video" class="vimeo" frameborder="0" :src="trainingData.video" allow="fullscreen"
          title="" trainingData-ready="true" tabindex="-1"></iframe>
      </div>
    </MDBCol>
    <MDBCol xl="7" cols="12" class="mb-1">
      <div class="textBox h-auto textBox__training">
        <h5 v-if="trainingData" v-html="trainingData.title"></h5>
        <div v-if="trainingData" v-html="trainingData.short_description"></div>
      </div>
    </MDBCol>
  </MDBRow>

  <Lightbox v-if="showLightBox" :video="trainingData.video" @close="(showValue) => (showLightBox = showValue)" />
  <Modal v-if="showSuccessModal" @close="(showValue) => (showSuccessModal = showValue)">
    <template v-slot:body>
      <h4 class="text-center">{{ $t("afterTraining.congratulations") }}</h4>
      <div class="text-center">{{ $t("afterTraining.allAnswer") }}!</div>
      <div class="text-center">
        {{ $t("afterTraining.points", { points: trainingData.points }) }}
      </div>
    </template>
  </Modal>

  <p v-if="apiError" class="text-danger text-small text-end m-0 pt-2 text-small">
    {{ apiError }}
    <router-link :to="{ name: 'stammdaten' }">{{ $t("afterTraining.clickToProfile") }}.</router-link>
  </p>
</template>

<script setup>
import { useRoute } from "vue-router";
import Lightbox from "./Inputs/Lightbox.vue";
import Modal from "./Inputs/Modal.vue";
import { MDBCol, MDBRow } from "mdb-vue-ui-kit";
import { ref, computed } from "vue";
import { useStore } from "vuex";

const store = useStore();
const route = useRoute();
let apiError = ref(null);
let showLightBox = ref(false);
let showSuccessModal = ref(false);

const fetchTraining = () => {
  store.dispatch("loadTrainingById", route.params.id);
};

fetchTraining();

const trainingData = computed(() => store.getters.getTraining);
</script>

<style scoped lang="scss">
@import "../../../styles/variables";
@import "@/styles/training.scss";

.vimeo {
  width: 100%;
  height: 300px;
}
</style>
