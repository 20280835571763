<template>
  <MDBRow>
    <MDBCol xl="5" cols="12" class="mb-1">
      <div class="textBox textBox--small h-auto">
        <div v-if="training" v-html="description"></div>
        <router-link v-if="!isWholesaler && isWtchedVideo" :to="{ name: 'trainingForm', id: route.params.id }"
          class="btn btn-danger text-white mt-4">{{ $t("buttons.toQuiz") }}</router-link>
      </div>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const store = useStore();
const route = useRoute();
const LANG = localStorage.getItem("lang");
const checkWatchVideoCountries = ref(['nl'])

const training = computed(() => store.getters.getTraining);
const profile = computed(() => store.getters.getProfile);
const isWtchedVideo = computed(() => {
  if (checkWatchVideoCountries.value.includes(LANG)) {
    return store.getters.getWatchedVideo
  } else {
    return true
  }
});

const user = computed(() => store.getters.getMe);
const isWholesaler = ref(false);
const description = ref(null);

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "wholesaler")) {
    isWholesaler.value = true;
  }
});
watch(training, async (newVal) => {
  if (newVal) {
    description.value = setDescription();
  }
});
const setDescription = () => {
  if (training.value && training.value.can_receive_points && training.value.can_receive_points.includes(profile.value.group_id)) {
    return training.value.description;
  } else {
    return training.value.default_description;
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
</style>
