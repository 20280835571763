<template>
  <MDBCard class="py-0 border-0">
    <div class="overview-header">
      <h2 class="font-20 font-xxxl-40 mb-4">
        {{ $t("notice") }}
      </h2>
      <MDBRow>
        <MDBCol col="12">
          <div>{{ $t("addCsNumber.body") }}</div>
          <div>
            <b>{{ $t("her") }}
              <span class="text-lowercase">{{
                $t("profile.clientNrSingular")
              }}</span>:</b>
            {{ data.customer_number }} {{ data.expected_name }}
          </div>
        </MDBCol>
        <MDBCol col="12" class="pt-3">
          <div class="d-flex justify-content-space-between align-items-center">
            <MDBBtn @click="saveCustomerNumber" type="submit" color="danger" :disabled="loading"
              class="d-flex justify-content-center align-items-center"><span>{{ $t("buttons.sendButton") }}</span>
              <Loader :show="loading" :small="true" spinner="spinner.svg" :tiny="true" />
            </MDBBtn>
            <MDBBtn class="btn-outline-dark" @click="closeModal"><span>{{ $t("close") }}</span>
            </MDBBtn>
          </div>
        </MDBCol>
      </MDBRow>
    </div>
  </MDBCard>
</template>

<script setup>
import { computed, defineProps, defineEmits } from "vue";
import Loader from "@/components/General/Loader/index.vue";
import { MDBBtn, MDBCard, MDBCol, MDBRow } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { UserService } from "@/services/user.service";
import i18n from "@/plugins/i18n";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const store = useStore();
const loading = computed(() => store.getters.getPending);
const emit = defineEmits(["close"]);

const props = defineProps({
  data: Object,
});

const saveCustomerNumber = async () => {
  const response = await UserService.saveCustomerNumber(props.data);

  if (response.status === 200) {
    await store.dispatch("me");

    emit("close", false);
    toast(i18n.global.t("toastMessages.customerNumberSaved"), {
      type: "success",
      theme: "colored",
      transition: toast.TRANSITIONS.SLIDE,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }

  if (response?.data.message === "exists") {
    toast(i18n.global.t("toastMessages.customerNumberExist"), {
      type: "error",
      theme: "colored",
      transition: toast.TRANSITIONS.SLIDE,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } else if (response?.data.message === "changeParentId") {
    toast(i18n.global.t("toastMessages.changeParentId"), {
      type: "error",
      theme: "colored",
      transition: toast.TRANSITIONS.SLIDE,
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
};


const closeModal = () => {
  emit("close", false);
};
</script>

<style scoped lang="scss">
.overview-header {
  padding: 60px 60px 40px 60px;
}
</style>
