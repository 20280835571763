<template>
  <MDBRow v-if="promotionsData.length > 0">
    <MDBCol cols="12" md="6" lg="4" class="mb-4 d-flex" v-for="(data, i) in promotionsData" :key="i">
      <Card :btn-class="'btn-primary'" :simple-link="true" :data="data" :linkText="data.linkText" target="_blank" />
    </MDBCol>
  </MDBRow>
  <MDBRow class="mt-4">
    <MDBCol lg="8">
      <div class="bg-white px-4 py-4 custom-card">
        <h2 class="title font-28 fw-bold">
          {{ $t("salesPromotions.participation.title") }}
        </h2>
        <div class="font-16" v-html="$t('salesPromotions.participation.desc')"></div>
        <div class="my-4" v-if="promotionsData.length > 0">
          <template v-for="(value, name) in promotionsData" :key="`promotion_${name}`">
            <MDBRow class="font-16">
              <MDBCol lg="1">
                <MDBSwitch v-model="promo[value.id]" :disabled="blockPromo[value.id]"
                  @change="choosePromotion(value.id)" />
              </MDBCol>
              <MDBCol lg="11">
                <span v-html="value.subtitle" class="fw-bold text-uppercase"></span> - <span
                  v-html="value.title"></span>
              </MDBCol>
            </MDBRow>
          </template>
        </div>
        <MDBRow>
          <MDBCol lg="6">
            <MDBBtn class="btn btn-danger text-uppercase align-self-start" :disabled="allPromotions"
              @click="saveUserPromotions">
              {{ $t("salesPromotions.participation.request") }}
            </MDBBtn>
          </MDBCol>
          <MDBCol v-if="success" lg="6" class="d-flex align-items-center">
            {{ $t("salesPromotions.participation.success") }}
          </MDBCol>
        </MDBRow>
      </div>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBRow, MDBCol, MDBSwitch, MDBBtn } from "mdb-vue-ui-kit";
import { ref, computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import i18n from "@/plugins/i18n";

import { UserService } from "@/services/user.service";
import Card from "@/components/General/Card/index.vue";

const store = useStore();

const promo = ref({})
const blockPromo = ref({})
const success = ref(false);
const allPromotions = ref(false);

const me = computed(() => store.getters.getMe);
const currentLocale = computed(() => store.getters.getCurrentLocale);
const userPromotions = computed(() => store.getters.getUserPromotions);
const promotionsData = computed(() => store.getters.getPromotions.map((item) => {
  return {
    name: `<span class="font-sm">${item.title[currentLocale.value]
      }</span><br/><h3 class="fw-bold text-uppercase">${item.subtitle[currentLocale.value]
      }</h3>`,
    description: item.description[currentLocale.value],
    link: item.file[currentLocale.value],
    image: item.image[currentLocale.value],
    linkText: i18n.global.t("buttons.dealerInfoDownload"),
    title: item.title[currentLocale.value],
    subtitle: item.subtitle[currentLocale.value],
    id: item.plainId,
  };
}))

watch(promotionsData, (newVal) => {
  if (newVal) {
    for (const value of newVal) {
      promo.value[value.id] = false
    }
  }
})

watch(userPromotions, (newVal) => {
  if (newVal.length > 0) {
    for (const promotion of newVal) {
      const existingIndex = Object.hasOwn(promo.value, promotion.promotion_id);
      promo.value[promotion.promotion_id] = existingIndex
      blockPromo.value[promotion.promotion_id] = existingIndex
    }
  }

});

const saveUserPromotions = async () => {
  let args = {
    user_id: me.value.plainId,
    promotions: promo.value,
  };

  const response = await UserService.saveUserPromotions(args);
  if (response.status == 200) {
    (success.value = true), await store.dispatch("loadUserPromotions");
    setTimeout(() => {
      success.value = false;
    }, 5000);
  }
};

const choosePromotion = (id) => {
  promo.value[id] = !promo.value[id]
}

onMounted(async () => {
  await store.dispatch("fetchPromotions");
  await store.dispatch("loadUserPromotions");
  await store.dispatch("me");
})
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

:deep(.font-sm) {
  font-size: 20px;
}

:deep(.custom-card) {
  display: flex;
  flex-direction: column;
}

:deep(.training-description) {
  margin-bottom: auto;
}

:deep(.form-switch .form-check-input:checked[type="checkbox"]),
:deep(.form-switch .form-check-input:checked[type="checkbox"]::after) {
  background-color: #ed1c24;
}

:deep(.form-switch .form-check-input:checked:focus::before) {
  box-shadow: 3px -1px 0px 13px #ed1c24;
}

.title {
  position: relative;

  &::before {
    content: "";
    display: inline-block;
    width: 28px;
    height: 4px;
    background: #ed1c24;
    transform: rotate(110deg);
    margin: 10px 0;
  }

  @media screen and (max-width: $mdBreakpoint) {
    font-size: 20px;
  }
}
</style>
